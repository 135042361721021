export const orderRequirement = `하단 내용을 자세히 기록해 주시면 견적 시 도움이 됩니다.



1. 해당 지역:


2. 내부 평수:


3. 좌석 수 (인터넷이 필요한):


4. 기기의 수 (복합기 등 인터넷이 필요한 기기):


5. 기타 참고 사항:`;
